body {
  margin: 0 auto;
  padding: 5px 5px;
  font-family: 'Open Sans', sans-serif;
  max-width: 750px;
  min-height: 100%;
  border: 2px solid rgb(62, 62, 194);
  text-align: center;
  background-color: #e6f0fa;
}

html {
  background-color: rgb(62, 62, 194);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.form-control {
  font-size: 2rem;
  padding: 25px 15px;
}
