.LomakeWrapperi {
  margin-top: 15px;
}

.TilastoWrapperi {
  border-radius: 5px;
}

.Numerokentta {
  width: 60px;
  text-align: center;
  font-size: 20px;
  border-radius: 5px;
  color: black;
}

.Desimaalikentta {
  width: 40px;
  text-align: center;
  font-size: 20px;
  color: black;
  border-radius: 5px;
}

.Aikakentta {
  text-align: left;
  margin-top: 10px;
  font-size: 20px;
}

.PlusMiinusNappi {
  width: 40px;
  height: 40px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 20px;
  margin-bottom: 8px;
}

.MiinusDesimaali {
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.PlusDesimaali {
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.DesimaaliRivi {
  margin-left: 5px;
}

.col-xs-4 {
  padding: 0px;
}

.col-xs-2 {
  padding: 0px;
}
