body {
  margin: 0 auto;
  padding: 5px 5px;
  font-family: 'Open Sans', sans-serif;
  max-width: 750px;
  min-height: 100%;
  border: 2px solid rgb(62, 62, 194);
  text-align: center;
  background-color: #e6f0fa;
}

html {
  background-color: rgb(62, 62, 194);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  background-size: cover;
}

.form-control {
  font-size: 2rem;
  padding: 25px 15px;
}

.LinkkiNappiPieni {
  width: 120px;
  height: 45px;
  padding: 3px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 3%;
  margin-right: 3%;
  background-color: rgb(37, 92, 174);
  color: white;
}
.LinkkiNappiPieni:hover,
.LinkkiNappiPieni:active {
  background-color: rgb(28, 70, 132);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.LinkkiNappi {
  width: 120px;
  height: 80px;
  padding: 16px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  margin: 10px 10px;
  background-color: rgb(37, 92, 174);
  color: white;
}

.LinkkiNappiMatala {
  width: 220px;
  height: 55px;
  padding: 16px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  margin: 0px 10px;
  background-color: rgb(37, 92, 174);
  color: white;
}

.LinkkiNappiLevea {
  width: 90%;
  height: 40px;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  margin: 10px 10px;
  background-color: rgb(37, 92, 174);
  color: white;
}
.LinkkiNappiLeveaDisabled {
    width: 90%;
    height: 40px;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 8px;
    box-shadow: 0 2px 3px #ccc;
    margin: 10px 10px;
    background-color: rgb(128, 128, 128);
    color: white;
}
.LinkkiNappi:hover,
.LinkkiNappi:active {
  background-color: rgb(28, 70, 132);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.LinkkiNappiNuoli {
  width: 40px;
  height: 40px;

  text-align: center;
  font-size: 12px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 4px;
  box-shadow: 0 0px 0px #ccc;
  margin: 0px 0px;
  background-color: rgb(37, 92, 174);
  color: white;
}

.ToimintamuotoOtsikko {
  border-bottom: solid rgb(28, 70, 132);
  margin: 15px;
}
.Toimintaryhmaotsikko {
  color: green;
}
.Toimintaryhmakokonaisuus {
  width: 100%;
  height: 60px;
  margin-left: 0%;
  margin-right: 0%;
  font-size: 19px;
  border-radius: 5px;
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(210, 210, 255)),
    to(rgb(191, 213, 245))
  );
  background-image: linear-gradient(
    to bottom,
    rgb(210, 210, 255) 0%,
    rgb(191, 213, 245) 100%
  );
  color: black;
}

.OnToimintaa {
  color: green;
  cursor: pointer;
}

.EiToimintaa {
  color: red;
  cursor: not-allowed;
}

.EiToimintaValintaa {
  color: black;
  cursor: not-allowed;
}

.glyphicon.glyphicon-arrow-left {
  padding: 10px;
  font-size: 20px;
}

.glyphicon.glyphicon-pencil {
  padding: 10px;
  font-size: 20px;
}
.glyphicon.glyphicon-arrow-right {
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}
.glyphicon.glyphicon-ok {
  font-size: 20px;
  padding: 10px;
}
.LisaaRyhma {
  width: 100%;
  height: 50px;
  font-size: 19px;
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(229, 0, 83)),
    to(rgb(178, 0, 65))
  );
  background-image: linear-gradient(
    to bottom,
    rgb(229, 0, 83) 0%,
    rgb(178, 0, 65) 100%
  );
  color: white;
  border-radius: 5px;
  font-size: 19px;
}
.MuokkaaRyhma {
  width: 100%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(37, 92, 174)),
    to(rgb(28, 70, 132))
  );
  background-image: linear-gradient(
    to bottom,
    rgb(37, 92, 174) 0%,
    rgb(28, 70, 132) 100%
  );
  color: white;
}
.PoistaRyhma {
  width: 100%;
  height: 50px;
  font-size: 19px;
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(229, 0, 83)),
    to(rgb(178, 0, 65))
  );
  background-image: linear-gradient(
    to bottom,
    rgb(229, 0, 83) 0%,
    rgb(178, 0, 65) 100%
  );
  color: white;
  border-radius: 5px;
  font-size: 19px;
}
.TakaisinRyhma {
  width: 100%;
  height: 50px;
  display: inline-block;
  padding: 10px;
  background-color: white;
  border: 2px solid rgb(229, 0, 83);
  color: black;
  font-size: 19px;
  border-radius: 5px;
}
.TallennaRyhma {
  width: 100%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(50, 184, 90)),
    to(rgb(15, 107, 46))
  );
  background-image: linear-gradient(
    to bottom,
    rgb(50, 184, 90) 0%,
    rgb(15, 107, 46) 100%
  );
  color: white;
}
.TallennaToimintamuodot {
  width: 100%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  padding: 3%;
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(50, 184, 90)),
    to(rgb(15, 107, 46))
  );
  background-image: linear-gradient(
    to bottom,
    rgb(50, 184, 90) 0%,
    rgb(15, 107, 46) 100%
  );
  color: white;
}

.TapahtumaRivi {
  max-height: 150px;
  text-align: left;
  background-color: rgb(37, 92, 174);
  margin: 5px 10px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  cursor: pointer;
}

.TapahtumaRivi:hover {
  background-color: rgb(28, 70, 132);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.Vaakaviiva {
  height: 2px;
  background-color: rgb(0, 0, 0);
  border: 0px;
}

.LomakeWrapperi {
  margin-top: 15px;
}

.OhjausKysymysWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.OhjeWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.VaittamaNappi {
  margin: 5px;
  margin-left: 25px;
  margin-right: 25px;
  border: outset white;
}
.VaittamaNappiValittu {
  margin: 5px;
  margin-left: 25px;
  margin-right: 25px;
  border: solid black;
  border-width: 6px;
  font-size: 14px;
}

.ValintaNappi {
  padding: 12px;
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
  background-color: rgb(37, 92, 174);
}

.OhjausOtsikkoWrapperi {
  background-color: rgb(212, 211, 211);
  color: black;
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 10px;
}

.TekstiKentta {
  width: 100%;
  border-radius: 5px;
}


.btn-success-new { 
  background-image: none;
  background-color: rgb(37, 92, 174);    
  border-color: rgb(0,0,0);
  color: #fff;
}

.btn-success-new:hover {
    background-color: rgb(28, 70, 132);
    border-color: rgb(0,0,0);
    color: #fff;
}

.btn.btn-success-new.active.focus,
.btn.btn-success-new.active:focus,
.btn.btn-success-new.active:hover,
.btn.btn-success-new:active.focus,
.btn.btn-success-new:active:focus,
.btn.btn-success-new:active:hover {
    background-color: rgb(28, 70, 132);
    border-color: rgb(0,0,0);
    color: #fff;
}


.SuodatinNappiValittu {
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(241, 241, 241)),
    to(#999999)
  );
  background-image: linear-gradient(
    to bottom,
    rgb(241, 241, 241) 0%,
    #999999 100%
  );
}

.MerkintalistausWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.SuodatinWrapperi {
  background-color: #d3d3d3;
  border-radius: 5px;
  padding: 15px;
}

.MerkintariviButtoni, .MerkintariviButtoni:hover, .MerkintariviButtoni:active { 
  width: -webkit-fill-available; 
  max-height: 150px;
  text-align: center;
  background-color: rgb(37, 92, 174);
  margin: 5px 10px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  color: white;
  padding: 5px 5px 5px 5px;
  background-image: none;
  text-shadow: 0 1px 0 #333; 
}
.MerkintariviButtoniAlert, .MerkintariviButtoniAlert:hover, .MerkintariviButtoniAlert:active {
    width: -webkit-fill-available;
    max-height: 150px;
    text-align: center;
    background-color: red;
    margin: 5px 10px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 8px;
    box-shadow: 0 2px 3px #ccc;
    color: white;
    padding: 5px 5px 5px 5px;
    background-image: none;
    text-shadow: 0 1px 0 #333;
}
.MerkintaRivi {
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.MerkintaOtsikko {
  margin-top: 5px;
}

.Muokkausnappi {
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}

.Muokkausteksti {
  margin: 0px;
}

.PaikkaRivi {
  text-align: left;
  padding-left: 15px;
}

.OhjeWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.KysymysWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.HaitariNappi {
  width: 100%;
  height: 50px;
  font-size: 19px;
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(229, 0, 83)),
    to(rgb(178, 0, 65))
  );
  background-image: linear-gradient(
    to bottom,
    rgb(229, 0, 83) 0%,
    rgb(178, 0, 65) 100%
  );
  color: white;
  border-radius: 5px;
  font-size: 19px;
}

.YhdistysNappi {
  width: 50%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(37, 92, 174)),
    to(rgb(28, 70, 132))
  );
  background-image: linear-gradient(
    to bottom,
    rgb(37, 92, 174) 0%,
    rgb(28, 70, 132) 100%
  );
  color: white;
}

.OtsikkoWrapperi {
  background-color: rgb(37, 92, 174);
  color: white;
  border-top: solid rgb(28, 70, 132);
  border-left: solid rgb(28, 70, 132);
  border-right: solid rgb(28, 70, 132);
  border-radius: 5px;
  padding: 10px;
  padding-top: 0px;
}

.KysymysWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.OmaOtsikkoKentta {
  margin-left: 10px;
}

.OhjeNappi {
  font-size: 22px;
  padding-top: 5px;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  color: blue;
}
.SininenNappi {
  width: 100%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(37, 92, 174)),
    to(rgb(28, 70, 132))
  );
  background-image: linear-gradient(
    to bottom,
    rgb(37, 92, 174) 0%,
    rgb(28, 70, 132) 100%
  );
  color: white;
}
.TallennaNappi {
  width: 100%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  background-image: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(50, 184, 90)),
    to(rgb(15, 107, 46))
  );
  background-image: linear-gradient(
    to bottom,
    rgb(50, 184, 90) 0%,
    rgb(15, 107, 46) 100%
  );
  color: white;
}

.LomakeWrapperi {
  margin-top: 15px;
}

.TilastoWrapperi {
  border-radius: 5px;
}

.Numerokentta {
  width: 60px;
  text-align: center;
  font-size: 20px;
  border-radius: 5px;
  color: black;
}

.Desimaalikentta {
  width: 40px;
  text-align: center;
  font-size: 20px;
  color: black;
  border-radius: 5px;
}

.Aikakentta {
  text-align: left;
  margin-top: 10px;
  font-size: 20px;
}

.PlusMiinusNappi {
  width: 40px;
  height: 40px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 20px;
  margin-bottom: 8px;
}

.MiinusDesimaali {
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.PlusDesimaali {
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.DesimaaliRivi {
  margin-left: 5px;
}

.col-xs-4 {
  padding: 0px;
}

.col-xs-2 {
  padding: 0px;
}

.YhdistysNappi {
  width: 90%;
  border-radius: 5px;
  margin: 5px;
}

.ValitutWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.ValintaWrapperi {
    width: 90%;
    padding: 15px;
    margin-left: 5%;
    margin-right: 5%;
}
