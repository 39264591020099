.ToimintamuotoOtsikko {
  border-bottom: solid rgb(28, 70, 132);
  margin: 15px;
}
.Toimintaryhmaotsikko {
  color: green;
}
.Toimintaryhmakokonaisuus {
  width: 100%;
  height: 60px;
  margin-left: 0%;
  margin-right: 0%;
  font-size: 19px;
  border-radius: 5px;
  background-image: linear-gradient(
    to bottom,
    rgb(210, 210, 255) 0%,
    rgb(191, 213, 245) 100%
  );
  color: black;
}

.OnToimintaa {
  color: green;
  cursor: pointer;
}

.EiToimintaa {
  color: red;
  cursor: not-allowed;
}

.EiToimintaValintaa {
  color: black;
  cursor: not-allowed;
}

.glyphicon.glyphicon-arrow-left {
  padding: 10px;
  font-size: 20px;
}

.glyphicon.glyphicon-pencil {
  padding: 10px;
  font-size: 20px;
}
.glyphicon.glyphicon-arrow-right {
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}
.glyphicon.glyphicon-ok {
  font-size: 20px;
  padding: 10px;
}
.LisaaRyhma {
  width: 100%;
  height: 50px;
  font-size: 19px;
  background-image: linear-gradient(
    to bottom,
    rgb(229, 0, 83) 0%,
    rgb(178, 0, 65) 100%
  );
  color: white;
  border-radius: 5px;
  font-size: 19px;
}
.MuokkaaRyhma {
  width: 100%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  background-image: linear-gradient(
    to bottom,
    rgb(37, 92, 174) 0%,
    rgb(28, 70, 132) 100%
  );
  color: white;
}
.PoistaRyhma {
  width: 100%;
  height: 50px;
  font-size: 19px;
  background-image: linear-gradient(
    to bottom,
    rgb(229, 0, 83) 0%,
    rgb(178, 0, 65) 100%
  );
  color: white;
  border-radius: 5px;
  font-size: 19px;
}
.TakaisinRyhma {
  width: 100%;
  height: 50px;
  display: inline-block;
  padding: 10px;
  background-color: white;
  border: 2px solid rgb(229, 0, 83);
  color: black;
  font-size: 19px;
  border-radius: 5px;
}
.TallennaRyhma {
  width: 100%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  background-image: linear-gradient(
    to bottom,
    rgb(50, 184, 90) 0%,
    rgb(15, 107, 46) 100%
  );
  color: white;
}
.TallennaToimintamuodot {
  width: 100%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  padding: 3%;
  background-image: linear-gradient(
    to bottom,
    rgb(50, 184, 90) 0%,
    rgb(15, 107, 46) 100%
  );
  color: white;
}

.TapahtumaRivi {
  max-height: 150px;
  text-align: left;
  background-color: rgb(37, 92, 174);
  margin: 5px 10px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  cursor: pointer;
}

.TapahtumaRivi:hover {
  background-color: rgb(28, 70, 132);
  transition: 0.3s;
}

.Vaakaviiva {
  height: 2px;
  background-color: rgb(0, 0, 0);
  border: 0px;
}
