.LomakeWrapperi {
  margin-top: 15px;
}

.OhjausKysymysWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.OhjeWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.VaittamaNappi {
  margin: 5px;
  margin-left: 25px;
  margin-right: 25px;
  border: outset white;
}
.VaittamaNappiValittu {
  margin: 5px;
  margin-left: 25px;
  margin-right: 25px;
  border: solid black;
  border-width: 6px;
  font-size: 14px;
}

.ValintaNappi {
  padding: 12px;
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
  background-color: rgb(37, 92, 174);
}

.OhjausOtsikkoWrapperi {
  background-color: rgb(212, 211, 211);
  color: black;
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 10px;
}

.TekstiKentta {
  width: 100%;
  border-radius: 5px;
}
