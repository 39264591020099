.OhjeWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.KysymysWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.HaitariNappi {
  width: 100%;
  height: 50px;
  font-size: 19px;
  background-image: linear-gradient(
    to bottom,
    rgb(229, 0, 83) 0%,
    rgb(178, 0, 65) 100%
  );
  color: white;
  border-radius: 5px;
  font-size: 19px;
}

.YhdistysNappi {
  width: 50%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  background-image: linear-gradient(
    to bottom,
    rgb(37, 92, 174) 0%,
    rgb(28, 70, 132) 100%
  );
  color: white;
}

.OtsikkoWrapperi {
  background-color: rgb(37, 92, 174);
  color: white;
  border-top: solid rgb(28, 70, 132);
  border-left: solid rgb(28, 70, 132);
  border-right: solid rgb(28, 70, 132);
  border-radius: 5px;
  padding: 10px;
  padding-top: 0px;
}

.KysymysWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.OmaOtsikkoKentta {
  margin-left: 10px;
}

.OhjeNappi {
  font-size: 22px;
  padding-top: 5px;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  color: blue;
}
.SininenNappi {
  width: 100%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  background-image: linear-gradient(
    to bottom,
    rgb(37, 92, 174) 0%,
    rgb(28, 70, 132) 100%
  );
  color: white;
}
.TallennaNappi {
  width: 100%;
  height: 50px;
  font-size: 19px;
  border-radius: 5px;
  background-image: linear-gradient(
    to bottom,
    rgb(50, 184, 90) 0%,
    rgb(15, 107, 46) 100%
  );
  color: white;
}
