
.btn-success-new { 
  background-image: none;
  background-color: rgb(37, 92, 174);    
  border-color: rgb(0,0,0);
  color: #fff;
}

.btn-success-new:hover {
    background-color: rgb(28, 70, 132);
    border-color: rgb(0,0,0);
    color: #fff;
}

.btn.btn-success-new.active.focus,
.btn.btn-success-new.active:focus,
.btn.btn-success-new.active:hover,
.btn.btn-success-new:active.focus,
.btn.btn-success-new:active:focus,
.btn.btn-success-new:active:hover {
    background-color: rgb(28, 70, 132);
    border-color: rgb(0,0,0);
    color: #fff;
}

