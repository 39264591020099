.LinkkiNappiPieni {
  width: 120px;
  height: 45px;
  padding: 3px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 3%;
  margin-right: 3%;
  background-color: rgb(37, 92, 174);
  color: white;
}
.LinkkiNappiPieni:hover,
.LinkkiNappiPieni:active {
  background-color: rgb(28, 70, 132);
  transition: 0.3s;
}

.LinkkiNappi {
  width: 120px;
  height: 80px;
  padding: 16px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  margin: 10px 10px;
  background-color: rgb(37, 92, 174);
  color: white;
}

.LinkkiNappiMatala {
  width: 220px;
  height: 55px;
  padding: 16px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  margin: 0px 10px;
  background-color: rgb(37, 92, 174);
  color: white;
}

.LinkkiNappiLevea {
  width: 90%;
  height: 40px;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  margin: 10px 10px;
  background-color: rgb(37, 92, 174);
  color: white;
}
.LinkkiNappiLeveaDisabled {
    width: 90%;
    height: 40px;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 8px;
    box-shadow: 0 2px 3px #ccc;
    margin: 10px 10px;
    background-color: rgb(128, 128, 128);
    color: white;
}
.LinkkiNappi:hover,
.LinkkiNappi:active {
  background-color: rgb(28, 70, 132);
  transition: 0.3s;
}
.LinkkiNappiNuoli {
  width: 40px;
  height: 40px;

  text-align: center;
  font-size: 12px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 4px;
  box-shadow: 0 0px 0px #ccc;
  margin: 0px 0px;
  background-color: rgb(37, 92, 174);
  color: white;
}
