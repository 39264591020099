.YhdistysNappi {
  width: 90%;
  border-radius: 5px;
  margin: 5px;
}

.ValitutWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.ValintaWrapperi {
    width: 90%;
    padding: 15px;
    margin-left: 5%;
    margin-right: 5%;
}