.SuodatinNappiValittu {
  background-image: linear-gradient(
    to bottom,
    rgb(241, 241, 241) 0%,
    #999999 100%
  );
}

.MerkintalistausWrapperi {
  border-top: solid gray;
  border-left: solid gray;
  border-right: solid gray;
  border-radius: 5px;
  padding: 15px;
}

.SuodatinWrapperi {
  background-color: #d3d3d3;
  border-radius: 5px;
  padding: 15px;
}

.MerkintariviButtoni, .MerkintariviButtoni:hover, .MerkintariviButtoni:active { 
  width: -webkit-fill-available; 
  max-height: 150px;
  text-align: center;
  background-color: rgb(37, 92, 174);
  margin: 5px 10px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 2px 3px #ccc;
  color: white;
  padding: 5px 5px 5px 5px;
  background-image: none;
  text-shadow: 0 1px 0 #333; 
}
.MerkintariviButtoniAlert, .MerkintariviButtoniAlert:hover, .MerkintariviButtoniAlert:active {
    width: -webkit-fill-available;
    max-height: 150px;
    text-align: center;
    background-color: red;
    margin: 5px 10px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 8px;
    box-shadow: 0 2px 3px #ccc;
    color: white;
    padding: 5px 5px 5px 5px;
    background-image: none;
    text-shadow: 0 1px 0 #333;
}
.MerkintaRivi {
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.MerkintaOtsikko {
  margin-top: 5px;
}

.Muokkausnappi {
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}

.Muokkausteksti {
  margin: 0px;
}

.PaikkaRivi {
  text-align: left;
  padding-left: 15px;
}
